export let siteMainAccentColor = "#DE6D25"

export let displayFont = `'Roboto Slab', serif`
export let displayFontWeight = {
  light: `300`,
  regular: `400`,
  bold: `700`}

export let textFont = `'Open Sans', sans-serif`
export let textFontWeight = {
  light: `300`,
  lightItalics: `300i`,
  regular: `400`,
  regularItalics: `400i`,
  semibold: `600`,
  semiboldItalics: `600i`,
  bold: `700`,
}

