import styled from 'styled-components'
import {Col} from 'reactstrap'
import React from 'react'
import {displayFont, textFont} from "./site_style"

let getSeperatorWithHeight = (heightInPx, backgroundColor) => {

  let StyledDiv = styled.div`
    height: ${heightInPx}px;
    background: ${ backgroundColor === undefined ? "transparent" : backgroundColor };
    
    `

  return <StyledDiv/>
}

let ZoomableImage = styled.img`
  
  &: hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
`

let getColOf3 = () => <Col md="3"/>;

let HeaderForSubtitle = styled.div`
  font-family: ${textFont}-Light;
  font-size: 16px;
  color: #161616;
  letter-spacing: -0.5px;
`

let HeaderForSubtitleWithLeftBorder = styled.div`
  font-family: OpenSans-Light;
  font-size: 16px;
  color: #161616;
  border-left: 7px solid #4A90E2;
  letter-spacing: -0.5px;
  padding-top: 5px;
  padding-left: 18px
`
let HeaderForSubtitleWithLeftBorderGift = styled.div`
  font-family: OpenSans-Light;
  font-size: 16px;
  color: #161616;
  border-left: 7px solid #21BBA3;
  letter-spacing: -0.5px;
  padding-top: 5px;
  padding-left: 18px
`


let H1 = styled.p`
  font-family: ${displayFont};
  font-size: 4rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
  margin-top: 0;
`

let H2 = styled.p`
  font-family: ${displayFont};
  font-size: 3rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
  margin-top: 0;
`

let H3 = styled.p`
  font-family: ${displayFont};
  font-size: 2.25rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
  margin-top: 0;
`

let H4 = styled.p`
  font-family: ${displayFont};
  font-size: 1.625rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
  margin-top: 0;
`

let H5 = styled.p`
  font-family: ${displayFont};
  font-size: 1.25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
  margin-top: 0;
`

let BodyText1 = styled.p`
  font-family: ${textFont};
  font-size: 1.25rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
  margin-top: 0;
`

let BodyText1Span = styled.span`
  font-family: ${textFont};
  font-size: 1.25rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
  margin-top: 0;
`

let BodyText2 = styled.p`
  font-family: ${textFont};
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
  margin-top: 0;
`

let BodyText3 = styled.p`
  font-family: ${textFont};
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
  margin-top: 0;
`

let Subtitle = styled.p`
  font-family: ${displayFont};
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.8px;
  color: #161616;
  margin-bottom: 0;
  margin-top: 0;
`

let Subtitle1 = styled.p`
  font-family: ${displayFont};
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
  margin-top: 0;
`

let Subtitle2 = styled.p`
  font-family: ${displayFont};
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
  margin-top: 0;
`

let Subtitle1OpenSans = styled.p`
  font-family: ${textFont};
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
  margin-top: 0;
`

let Subtitle2OpenSans = styled.p`
  font-family: ${textFont};
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
  margin-top: 0;
`

let Caption = styled.p`
  font-family: ${textFont};
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #000000;
  margin-bottom: 0;
  margin-top: 0;
`

let Subtitle1OpenSansWithLightColor = styled.p`
  font-family: ${textFont};
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808080;
  margin-bottom: 0;
  margin-top: 0;
`

export {getColOf3, getSeperatorWithHeight,ZoomableImage, H1, H2, H3,H4, H5, Subtitle1, Subtitle1OpenSans, Subtitle2, Subtitle2OpenSans, Caption, BodyText1, BodyText2, BodyText3, Subtitle1OpenSansWithLightColor, BodyText1Span};
