import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import {Row, Col} from 'reactstrap'
import { displayFont, displayFontWeight } from './site_style'
import styled from "styled-components"


const NavigationLink = styled(Link)`
  && {
    color: ${props => props.frontPage ? "#ffffff" : "black"};
    text-decoration: none;
    padding: 4px 8px 4px 8px;
    border-radius:4px;

    &:hover {
      color: ${props => props.frontPage ? "#ffffff" : "#DE6D25"};
      background-color: ${props => props.frontPage ? "#D86A25" : "transparent"};
    }
  }
`

const Header = ({ siteTitle, frontPage }) => (
  <Row
    style={{
      height:`60px`,
      background: `transparent`,
      marginBottom: `1.45rem`,
      marginTop: `15px`,
      position: frontPage ? "absolute": "relative",
      width:"100vw",
      zIndex:3
    }}
  >
    <Col md={1}/>
    <Col md={4} style={{display:"flex", alignItems:"flex-end"}}>
    {/*<div*/}
      {/*style={{*/}
        {/*margin: `0 auto`,*/}
        {/*maxWidth: 960,*/}
        {/*padding: `1.45rem 1.0875rem`,*/}
      {/*}}*/}
    {/*>*/}

        <Link
          to="/"
          activeClassName={"linkHeader"}

          style={{color:`black`, textDecoration:`none`, display:"flex", flexDirection: "row", alignItems:"flex-end"}}
        >
          <span style={{
            // position: `absolute`,
            // left: `0px`,
            // bottom:`0px`,
            lineHeight:"30px", 
            fontSize:"28px",
            fontFamily: "'Alex Brush', cursive",
            textDecoration: `none`,
            color: frontPage ? "#ffffff" : "#000000"
          }}> Junjie Xu </span>

          <span style={{
            // position: `absolute`,
            // left: `0px`,
            // bottom:`0px`,
            fontSize:"23px",
            textDecoration: `none`,
            fontFamily: displayFont,
            fontWeight: displayFontWeight.light,
            paddingLeft: `20px`,
            color: frontPage ? "#ffffff" : "#000000"
          }}>  / User Experience </span>
        </Link>
    </Col>

    <Col md={3}/>

    <Col md={1} style={{display:"flex", alignItems:"flex-end"}}>
      <NavigationLink
        to="/"

        className={"link2"}

        activeStyle={{
          color: frontPage ? "#ffffff" : "#DE6D25",
          backgroundColor: frontPage ? "#D86A25" : "transparent",
        }}
        // style={{
        //   color: frontPage ? "#ffffff" : "black",
        //   textDecoration: 'none'
        // }}
        frontPage={frontPage}
      >
      <span style={{
        // position: `absolute`,
        // left: `0px`,
        // bottom:`0px`,
        fontSize:"18px",
        fontFamily: displayFont,
        fontWeight: displayFontWeight.light,
        
      }}> Portfolio </span>
      </NavigationLink>
    </Col>

    <Col md={1} style={{display:"flex", alignItems:"flex-end"}}> 
      <NavigationLink
        to="/resume/"

        className={"link2"}

        activeStyle={{
          color: frontPage ? "#ffffff" : "#DE6D25" ,
        }}
        frontPage={frontPage}
        // style={{
        //   color: frontPage ? "#ffffff" : "black",
        //   textDecoration: 'none'
        // }}
      >

      <span style={{
        // position: `absolute`,
        // left: `0px`,
        // bottom:`0px`,
        fontSize:"18px",
        textDecoration: `none`,
        fontFamily: displayFont,
        fontWeight: displayFontWeight.light,
      }}> Resume </span>
      </NavigationLink>
    </Col>

    <Col md={1} style={{display:"flex", alignItems:"flex-end"}}>
      <NavigationLink
        to="/about"

        className={"link2"}

        activeStyle={{
          color: frontPage ? "#ffffff" : "#DE6D25" ,
        }}
        frontPage={frontPage}
      >
      <span style={{
        // position: `absolute`,
        // left: `0px`,
        // bottom:`0px`,
        fontSize:"18px",
        textDecoration: `none`,
        fontFamily: displayFont,
        fontWeight: displayFontWeight.light,
      }}> Me </span>
      </NavigationLink>
    </Col>

  </Row>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
