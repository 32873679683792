import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'
import {displayFont, displayFontWeight, textFont, textFontWeight} from '../site_style'

let accentColor = `#21BBA3`
let backgroundColor = "#FDFFFF"
let mainTextColor = "#00061D"

let Title = styled.div`

    font-family: ${displayFont};
    font-weight: ${displayFontWeight.bold};
    font-size: 64px;
    color: ${accentColor};
    letter-spacing: -1.42px;
`

let Acknowledgement = styled.div`
    font-family: ${displayFont};
    font-weight: ${displayFontWeight.light};
    font-size: 16px;
    color: ${mainTextColor};
    letter-spacing: 0px;
`

export let Brief = styled.div`
    font-family: ${displayFont};
    font-size: 26px;
    color: ${mainTextColor};
    letter-spacing: -0.41px;
    line-height: 40px;
`

export let IntroLine = styled.div`

  font-family: ${textFont};
  font-weight: ${textFontWeight.regular};
  font-size: 16px;
  line-height: 28px;
  color: ${mainTextColor};
  text-align: left;
`

export let IntroSubHeader = styled.div`
  font-family: ${displayFont};
  font-weight: ${displayFontWeight.regular};
  font-size: 16px;
  line-height: 28px;
  color: ${mainTextColor};
  text-align: left;
`

export let Header2WithLeftBoarder = styled.div`
  font-family: ${displayFont};
  font-weight: ${displayFontWeight.bold};
  font-size: 36px;
  color: ${mainTextColor};
  letter-spacing: -0.5px; 
  border-left: 7px solid ${accentColor};
  line-height: 36px;
  padding-left: 18px;
`

export let Header2SubtitleWithLeftBorder = styled.div`

  font-family: ${displayFont};
  font-weight: ${displayFontWeight.light};
  font-size: 16px;
  color: ${mainTextColor};
  border-left: 7px solid ${accentColor};
  letter-spacing: -0.5px;
  padding-top: 10px;
  padding-left: 18px
`

export let MainText1 = styled.div`
  font-family: ${textFont};
  font-weight: ${textFontWeight.regular};
  font-size: 16px;
  color: ${mainTextColor};
  letter-spacing: -0.5px;
  line-height: 30px;
`

export let MainText1Bold = styled.span`
  font-weight: ${textFontWeight.bold};
`

export let MainText1SemiBold = styled.span`
  font-weight: ${textFontWeight.semibold};
`

export let HoverAnimatedMainText1 = styled(MainText1)`
    // background-color: ${"#E2FBF7"};
    background-position: -0% 0;
    background-size: 200% auto;
    background-image: linear-gradient(to right, rgba(255,255,255,0) 50%, #E2FBF7 50%);
    transition: background-position 0.5s ease-out; 
 
`


export let Header4WithLeftBorder = styled.div`
  font-family: ${displayFont};
  font-weight: ${displayFontWeight.bold};
  font-size: 20px;
  color: ${mainTextColor};
  letter-spacing: -0.5px; 
  border-left: 7px solid ${accentColor};
  line-height: 24px;
  padding-left: 10px;
 
`

export let Header4 = styled.div`
  font-family: ${displayFont};
  font-weight: ${displayFontWeight.bold};
  font-size: 20px;
  color: ${mainTextColor};
  letter-spacing: -0.5px; 
`


export let AccordianMainText1 = styled(MainText1)`
 
  font-size: 15px;
  color: ${mainTextColor};
  letter-spacing: -0.5px;
  line-height: 28px;
`

export let Header3 = styled.div`
  font-family: ${displayFont};
  font-size: 24px;
  color: #161616;
  letter-spacing: -0.67px;
`

export let Header3WithLeftBorder = styled.div`
  font-family: ${displayFont};
  font-size: 24px;
  font-weight: ${displayFontWeight.bold};
  color: #161616;
  letter-spacing: -0.67px;
  border-left: 7px solid #21BBA3;
  line-height: 38px;
  padding-left: 18px
`

export let ZoomableImage = styled.img`
  
  &: hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
    
  }
`
export let ImageCaption = styled.div`

    font-family: ${textFont};
    font-weight: ${textFontWeight.light};
    font-style:italic;
    font-size: 13px;
    color: #030303;
    letter-spacing: 0px;
`


export {displayFont,displayFontWeight, textFont, textFontWeight, accentColor, backgroundColor, mainTextColor, Title, Acknowledgement}