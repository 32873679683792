import React from "react"
import {Col, Row} from "reactstrap"
import {textFont} from '../components/hci_foundation/hci_foundation_style'

const Footer = ({children}) => {
  return (
    <div>
      <Row className="d-flex justify-content-center" style={{flexDirection:"row"}}>
        <a href="http://www.linkedin.com/in/junjie-xu-jj" style={{marginBottom:`8px`, color: `#DE6D25`}}> Linkedin</a>
        <a href="https://github.com/xujunjiejack" style={{marginBottom:`8px`, marginLeft:`8px`,color: `#DE6D25`}}> Github </a>
      </Row>

      <Row className="d-flex justify-content-center" style={{fontFamily:textFont, color: "#525252"}}>
        <p> Hand coded by Junjie Xu © 2017-2020 All Rights Reserved </p>
      </Row>
    </div>
)
}

export default Footer