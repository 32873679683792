import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import styled from 'styled-components'

import Header from './header'
import {Container} from 'reactstrap'
import './layout.css'

const StyledContainer = styled(Container)`
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
`


const Layout = ({ children, frontPage, onScroll}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} frontPage={frontPage} />
        <div
          style={{
            margin: `0 auto`,
            width: `100%`,
            padding: `0px 1.0875rem 1.0875rem`,
            paddingTop: 0,
            display:"flex",
            flexDirection:"column",
            // overflowY: "scroll",
            // overflowX: "auto"
            // justifyContent:"center"
          }}
        >
          {children}
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export {Layout, StyledContainer}
